import React from "react";
import RunSerialNone from "./components/RunSerialNone";
import RunSerialQuestionNone from "./components/RunSerialQuestionNone";
import RunSerialMobileNone from "./components/RunSerialMobileNone";
import RunSerialForceMobileNone from "./components/RunSerialForceMobileNone";
import RunSerialSearch from "./components/RunSerialSearch";
import RunSerialForceMobileSearch from "./components/RunSerialForceMobileSearch";
import RunSerialMobileSearch from "./components/RunSerialMobileSearch";
import RunSerialQuestionsSearch from "./components/RunSerialQuestionsSearch";
import RunSerialSelect from "./components/RunSerialSelect";
import RunSerialForceMobileSelect from "./components/RunSerialForceMobileSelect";
import RunSerialMobileSelect from "./components/RunSerialMobileSelect";
import RunSerialQuestionsSelect from "./components/RunSerialQuestionsSelect";
import IdentityPasswordNone from "./components/IdentityPasswordNone";
import RunSerialMailPasswordNone from "./components/RunSerialMailPasswordNone";
import RunSerialMailPasswordSearch from "./components/RunSerialMailPasswordSearch";
import RunSerialMailPasswordSelect from "./components/RunSerialMailPasswordSelect";
import RunSerialPasswordNone from "./components/RunSerialPasswordNone";
import RunSerialPasswordSelect from "./components/RunSerialPasswordSelect";
import RunSerialPasswordSearch from "./components/RunSerialPasswordSearch";
import IdentityPasswordSelect from "./components/IdentityPasswordSelect";
import IdentityPasswordSearch from "./components/IdentityPasswordSearch";
import MatiNone from "./components/MatiNone";
import MatiSearch from "./components/MatiSearch";
import MatiSelect from "./components/MatiSelect";
import TocClaveUnicaSelect from "./components/TocClaveUnicaSelect";
import TocClaveUnicaNone from "./components/TocClaveUnicaNone";
import TocClaveUnicaSearch from "./components/TocClaveUnicaSearch";
import RunSerialClaveUnicaNone from "./components/RunSerialClaveUnicaNone";
import RunSerialClaveUnicaSearch from "./components/RunSerialClaveUnicaSearch";
import RunSerialClaveUnicaSelect from "./components/RunSerialClaveUnicaSelect";
import RunSerialForceMobileClaveUnicaNone from "./components/RunSerialForceMobileClaveUnicaNone";
import RunSerialQuestionClaveUnicaNone from "./components/RunSerialQuestionClaveUnicaNone";
import RunSerialQuestionClaveUnicaSearch from "./components/RunSerialQuestionClaveUnicaSearch";
import RunSerialQuestionClaveUnicaSelect from "./components/RunSerialQuestionClaveUnicaSelect";
import RunSerialPasswordAutentizClaveUnicaNone
    from "./components/ComoVotarAutentiz/RunSerialPasswordAutentizClaveUnicaNone";
import RunSerialPasswordAutentizClaveUnicaSelect
    from "./components/ComoVotarAutentiz/RunSerialPasswordAutentizClaveUnicaSelect";
import RunSerialPasswordAutentizClaveUnicaSearch
    from "./components/ComoVotarAutentiz/RunSerialPasswordAutentizClaveUnicaSearch";

import {getMessage} from "./util";


const ComoVotar = (props) => {
    const district_text = props.election.configuration.district_text? props.election.configuration.district_text:"distrito" ;
    const getInstructions = (identification_districts) => {
        switch (identification_districts) {
            case "identity+password-none":
                return (<IdentityPasswordNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "identity+password-select":
                return (<IdentityPasswordSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "identity+password-search":
                return (<IdentityPasswordSearch messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial-none":
                return (<RunSerialNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_force_mobile-none":
                return (<RunSerialForceMobileNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_mobile-none":
                return (<RunSerialMobileNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_questions-none":
                return (<RunSerialQuestionNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial-search":
                return (<RunSerialSearch messages={props.election.messages}  configuration={props.election.configuration}/>);
            case "run+serial_force_mobile-search":
                return (<RunSerialForceMobileSearch messages={props.election.messages}  configuration={props.election.configuration}/>);
            case "run+serial_mobile-search":
                return (<RunSerialMobileSearch messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_questions-search":
                return (<RunSerialQuestionsSearch messages={props.election.messages}  configuration={props.election.configuration}/>);
            case "run+serial-select":
                return (<RunSerialSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_force_mobile-select":
                return (<RunSerialForceMobileSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_mobile-select":
                return (<RunSerialMobileSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_questions-select":
                return (<RunSerialQuestionsSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+external_password-none":
                return (<RunSerialMailPasswordNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+external_password-select":
                return (<RunSerialMailPasswordSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+external_password-search":
                return (<RunSerialMailPasswordSearch messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_password-none":
                return (<RunSerialPasswordNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_password-search":
                return (<RunSerialPasswordSearch messages={props.election.messages} configuration={props.election.configuration}/>);
            case "run+serial_password-select":
                return (<RunSerialPasswordSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "mati-none":
                return (<MatiNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "mati-search":
                return (<MatiSearch messages={props.election.messages}  configuration={props.election.configuration}/>);
            case "mati-select":
                return (<MatiSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "toc_clave_unica-none":
                return (<TocClaveUnicaNone messages={props.election.messages} configuration={props.election.configuration}/>);
            case "toc_clave_unica-search":
                return (<TocClaveUnicaSearch messages={props.election.messages}  configuration={props.election.configuration}/>);
            case "toc_clave_unica-select":
                return (<TocClaveUnicaSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial|toc_clave_unica-none":
                return (<RunSerialClaveUnicaNone messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial|toc_clave_unica-search":
                return (<RunSerialClaveUnicaSearch messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial|toc_clave_unica-select":
                return (<RunSerialClaveUnicaSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_force_mobile|toc_clave_unica-none":
                return (<RunSerialForceMobileClaveUnicaNone messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_questions|toc_clave_unica-none":
                return (<RunSerialQuestionClaveUnicaNone messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_questions|toc_clave_unica-select":
                return (<RunSerialQuestionClaveUnicaSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_questions|toc_clave_unica-search":
                return (<RunSerialQuestionClaveUnicaSearch messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>);
            case "run+serial_pasword|oauth2+autentiz_clave_unica-none":
                return <RunSerialPasswordAutentizClaveUnicaNone messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>
            case "run+serial_pasword|oauth2+autentiz_clave_unica-select":
                return <RunSerialPasswordAutentizClaveUnicaSelect messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>
            case "run+serial_pasword|oauth2+autentiz_clave_unica-search":
                return <RunSerialPasswordAutentizClaveUnicaSearch messages={props.election.messages} district_text={district_text} configuration={props.election.configuration}/>
            default:
                return (<RunSerialNone messages={props.election.messages} configuration={props.election.configuration}/>);

        }

    }

        return (
            <div className="row justify-content-center EvColorBlue mt-4">
                <div className="col-12 text-center text-white">
                    <div className="row">
                        <div className="col-12 text-center ">
                            <h1 className=" font-weight-bold mt-4 mb-4">{getMessage("como_votar_titulo", props.messages)}</h1>
                        </div>
                    </div>
                    {getInstructions(props.election.configuration.identification_field.concat("-" + props.election.configuration.districts))}
                </div>
            </div>
        );
    
}

export default ComoVotar;