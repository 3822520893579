import React, { Fragment} from "react";
import logomin_img from"./images/logomin.svg";
import {getMessage} from "./util";

const version = require('../package.json');

const Footer =(props)=> {

        return (
            <Fragment>
                <div className="row justify-content-center EvColorBlue align-items-center">
                    <div className="col-2 mt-4 mb-4 text-white text-right">
                        <img src={logomin_img} className="img-max-100" alt="footer"/>
                    </div>
                    <div className="col-md-6 col-8 mt-4 mr-4 mb-4 text-white font-weight-bold">
                        <p>{getMessage("correo", props.messages)}: {getMessage("correo_soporte", props.messages)}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right">
                        <small>© EVoting {new Date().getFullYear()}, v. {version.version}</small>
                        </div>
                </div>
            </Fragment>

        );
    
}


export default Footer;