import React from 'react';
import elegir_img_x from "../images/elegir_x.svg"
import elegir_img_y from "../images/elegir_b-01.svg";
import confirmar_img from "../images/confirmar respuestas_b-01.svg";
import biometrico_img from "../images/biometrico.svg";
import {getMessage} from "../util";

const MatiNone = (props) => {
    const elegir_img = (props.configuration.square_image ? elegir_img_x : elegir_img_y);
    return(
        <div className="row mb-4">
            <div className="col-12 col-sm-4 text-center">
                <p className="como-title mt-4">1. {getMessage("paso_elegir", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("instruccion_elegir", props.messages)}</p>
                <img src={elegir_img} className="img-max-300" alt="Cómo"/>
            </div>

            <div className="col-12 col-sm-4 text-center border-left">
                <p className="como-title mt-4">2. {getMessage("paso_confirmar", props.messages)}</p>
                <p className="font-italic h5 vp-75">{getMessage("instruccion_confirmar", props.messages)}</p>
                <img src={confirmar_img} className="img-max-300" alt="Confirmar"/>
            </div>

            <div className="col-12 col-sm-4 text-center border-left">
                <p className="como-title mt-4">3. {getMessage("paso_votar", props.messages)}</p>

                <p className="font-italic h5 vp-75">{getMessage("instruccion_votar_biometrico", props.messages)}</p>



                <img src={biometrico_img} className="img-max-300" alt="Votar"/>
            </div>




        </div>
    )
}

export default MatiNone;