import React from "react";
import logo_img  from "./images/logo.svg";

const Header =()=> {

        return (
            <div className="row EvColorGrisLight">
                <div className="col-6 col-sm-4 col-md-3 ">
                    <img src={logo_img} className="img-fluid logoEV" alt="logo"/>
                </div>
            </div>
        );
    }


export default Header;