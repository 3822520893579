import React, { Fragment} from 'react';
import LoadingSpinner from "./LoadingSpinner";
const dateFormat = require("dateformat");

const CloseElection =(props)=> {

        return (
            <Fragment>
                <div className="container-fluid">
                    {console.log(props)}

                    {props.state.loading ? (
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-11  text-center m-4 ">
                                <LoadingSpinner/>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            <Fragment>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-sm-11  text-center m-4 ">
                                        <img
                                            className="img-fluid logo"
                                            alt="Logo"
                                            src={props.state.client.logo_url}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-11  text-center m-4 ">
                                        <h2>{props.state.client.organization_name}</h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-sm-11 text-center">
                                        <h2 className="font-italic">{props.election.organization_name}</h2>
                                        {props.election.description && <h5>{props.election.description}</h5>}
                                    </div>
                                </div>
                            </Fragment>

                            <div className="row justify-content-center">

                                {props.election.map(e=>(
                                    <div className="col-10 border rounded border-danger text-center m-4 p-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <h4>{e.name}</h4>
                                                <h5>Inicio: {dateFormat(new Date(e.configuration.start_date), "dd '/' mm '/' yyyy 'a las' HH'.'MM 'hrs.'")}</h5>
                                                <h5>Fin: {dateFormat(new Date(e.configuration.end_date), "dd '/' mm '/' yyyy 'a las' HH'.'MM 'hrs.'")}</h5>
                                            </div>
                                            <div className="col-6">
                                                <a className="boton resultados EvColor" href={e.configuration.bulletin_url.replace(/%s/g, e._id)}>Ver Resultados</a>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div>


                        </Fragment>
                    )}


                </div>

            </Fragment>

        );
    }


export default CloseElection;