import React from "react";
import {getMessage} from "./util";

const Dudas =(props)=> {

        return (
            <div className="row justify-content-center align-items-center dudas EvColorGrisLight ">
                <div className="col-12 col-md-6  dudas-box p-4">
                    <h2 className="font-weight-bold mt-4 mb-4 text-center ">{getMessage("titulo_ayuda_1", props.messages)}<br/>{getMessage("titulo_ayuda_2", props.messages)}</h2>
                    <h4 className="font-italic h4 mb-4 text-center">{getMessage("mesa_ayuda", props.messages)}</h4>
                    <div><span className="linespanblack"></span></div>
                </div>
                <div className="col-12 col-md-6 text-center h5 mt-4 font-weight-bold">
                    <p>{getMessage("correo", props.messages)} / {getMessage("correo_soporte", props.messages)} </p>
                    <p>{getMessage("telefono", props.messages)} / {getMessage("telefono_soporte", props.messages)} </p>
                </div>
            </div>

        );
    }

export default Dudas;