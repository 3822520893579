import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../HTTPClient";
import {getMessage} from "./../util";

const getSeconds = async (end_date) => {

    const got_time = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/now"
    });


    const now = new Date(got_time.data);
    const end = new Date(end_date);
    return Math.round((end.getTime() - now.getTime()) / 1000);

}

const Clock = (props) => {
    const [distance, setDistance] = useState("00:00");
    const [secondsToEnd, setSecondsToEnd] = useState(100);


    useEffect(() => {

        const hours = Math.floor((secondsToEnd % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((secondsToEnd % (60 * 60)) / 60);
        const days = Math.floor((secondsToEnd) / (60 * 60 * 24));

        const seconds = (secondsToEnd % 60);
        if (secondsToEnd <= 0) {
            setDistance("00:00");
        }
        const s_label = secondsToEnd > 0 ? ((minutes < 10 ? ('0' + minutes) : minutes) + ':' + (seconds < 10 ? ('0' + seconds) : seconds)) : "00:00";


        setDistance(days > 0?(days+(days !== 1 ? " "+getMessage("dias", props.messages)+" ": " "+getMessage("dia", props.messages)+" ")+hours+":"+s_label):(hours>0?hours+":"+s_label:s_label) );

    }, [secondsToEnd]);


    useEffect(() => {
            let intervalId = null;
            (async () => {
                try {
                    let seconds = await getSeconds(props.date);

                    setSecondsToEnd(seconds);
                    intervalId = setInterval(() => {
                        (async () => {
                            // resync cada 5 segundos
                            if (seconds % 5 === 0) {
                                seconds = await getSeconds(props.date);
                            } else {
                                seconds--;
                            }
                            if (seconds < 0) {
                                clearInterval(intervalId);
                            }

                            setSecondsToEnd(seconds);
                        })()
                    }, 1000);


                } catch (error) {
                    setDistance("sincronizando...");
                }
            })()
            return (() => {
                clearInterval(intervalId);
            });
        }, [props.date]
    )


    return (
        <Fragment>
            <p>
                {distance}
            </p>
        </Fragment>

    )
}

export default Clock;