import React from 'react';
import elegir_img_x from "../images/elegir_x.svg"
import elegir_img_y from "../images/elegir_b-01.svg";
import confirmar_img from "../images/confirmar respuestas_b-01.svg";
import datos_cedula_img from "../images/preguntas_datos_cedula.svg";
import preguntas_img from "../images/preguntas_dep_voto.svg";
import {getMessage} from "../util";

const RunSerialQuestionNone = (props) => {
    const elegir_img = (props.configuration.square_image ? elegir_img_x : elegir_img_y);
        return(
            <div className="row mb-4">
                <div className="col-12 col-md-3 text-center">
                    <p className="como-title mt-4">1. {getMessage("paso_elegir", props.messages)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_elegir", props.messages)}</p>
                    <img src={elegir_img} className="img-max-300" alt="Cómo"/>
                </div>
                <div className="col-12 col-md-3 text-center border-left">
                    <p className="como-title mt-4">2. {getMessage("paso_confirmar", props.messages)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_confirmar", props.messages)}</p>
                    <img src={confirmar_img} className="img-max-300" alt="Confirmar"/>
                </div>
                <div className="col-12 col-md-3 text-center border-left">
                    <p className="como-title mt-4">3. {getMessage("paso_identificarse", props.messages)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_identificarse_preguntas", props.messages)}</p>
                    <img src={datos_cedula_img} className="img-max-300" alt="Votar"/>
                </div>
                <div className="col-12 col-md-3 text-center border-left">
                    <p className="como-title mt-4">4. {getMessage("paso_votar", props.messages)}</p>
                    <p className="font-italic h5 vp-75">{getMessage("instruccion_votar_preguntas", props.messages)}</p>
                    <img src={preguntas_img} className="img-max-300" alt="Validación"/>
                </div>
            </div>
        )
    }
export default RunSerialQuestionNone;