import React from "react";

const Video =(props)=> {

        return (
            <div className="row justify-content-center">
                <div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center m-4 ">
                    <div className="embed-responsive embed-responsive-16by9">
                        { props.video_url && <iframe className="embed-responsive-item" src={props.video_url} title="video"
                                allowFullScreen></iframe>}
                    </div>
                </div>
            </div>);
    }


export default Video;