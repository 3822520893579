import default_messages from "./messages.json";


export const getMessage = (key, messages) => {
    const getDefaultMessage = (key) => {
        if (!default_messages.default[navigator.language]) {
            if (!default_messages.default[key]) {
                return (default_messages.default.nokey);
            }
            return (default_messages.default[key]);
        } else {
            if (!default_messages.default[navigator.language][key]) {
                if (!default_messages.default[key]) {
                    return (default_messages.default.nokey);
                }
                return (default_messages.default[key]);
            } else {
                return (default_messages.default[key]);
            }
        }
    }

    if (messages && messages[navigator.language] && messages[navigator.language][key]) {
        return (messages[navigator.language][key]);
    }

    if (messages && messages.default && messages.default[key]) {
        return (messages.default[key]);
    }

    return (getDefaultMessage(key));

}

